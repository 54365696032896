/**
 *  Page - Treatments
 *  Categories
 */

.treatments-categories {
	background: linear-gradient(180deg, rgba(243, 240, 236, 0) 0%, #F3F0EC 100%);
  .categories-list {
    row-gap: 80px;
    padding-bottom: 100px;
  }

  .category-item {
    [class*="button"] {
      width: 100%;
      max-height: 84px;
      @include flex(center, center);
    }
  }

  @include mq-max(900) {
    .categories-list {
      grid-template-columns: 1fr;
      justify-items: center;
      row-gap: 32px;
      padding-bottom: 80px;
    }

    .category-item {
      @include wh(100%, $max-w: 420px);
    }

    .category-item:last-child {
      margin-top: 24px;

      .category-img {
        margin-bottom: 24px;
      }
    }
  }
}
