//
// Base
// Base
//

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
 -webkit-font-smoothing: antialiased;
 overflow-x: hidden;
 background-color: get-color(background, 1);
 color: get-color(text, 1);
}

img {
  max-width: 100%;
}

ul,
li {
  list-style-type: none;
}
