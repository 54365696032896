/**
 *  Page - Concept
 *  Hero
 */

.page-hero {
  padding-bottom: 100px;
  background: linear-gradient(180deg, rgba(243, 240, 236, 0) 0%, #F3F0EC 100%);

  p + p {
    margin-top: 15px;
  }

  .grid-2 {
    margin-top: 64px;
  }

  @include mq-max(900) {
    p {
      max-width: $col-6;
      margin-top: 15px;
    }

    .grid-2 {
      grid-template-columns: 1fr;
      justify-items: center;
      row-gap: 8px;
      margin-top: 8px;
    }
  }
}
