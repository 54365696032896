/**
 *  Page - Home
 *  Hero
 */

.hero {
  position: relative;
  padding-bottom: 40px;

  .container {
    position: initial;
  }
  
  &-main{
	padding-bottom: 30px
}

  &-header {
    padding: 32px 0 50px;
    text-align: center;

    .hero-overtitle {
      margin-bottom: 56px;
    }

    .hero-title {
      max-width: 710px;
    }
  }
  

  &-actions {
    grid-template-columns: 2fr 6fr 2fr;

    nav {
      align-self: center;
      @include flex($dir: column, $gap: 18px);
    }

    nav.actions-right {
      .button-wrapper {
        a, & {
          width: 100%;
        }

        @include flex($align: center, $dir: column, $gap: 8px);

        p {
          color: get-color(secondary, 1);
        }
      }
    }
  }

  &-image {
    text-align: center;

    img {
      max-width: $col-5;
    }
  }

  // hero-intro
  &-intro {
    text-align: center;
    padding: 30px 0 110px;

    &::after {
      content: '';
      display: block;
      @include pos($bottom: 0, $z: -1);
      @include wh(100%, 760px);
      background: linear-gradient(180deg, rgba(238, 231, 222, 0) 0%, #E2D7C8 100%);
    }
  }

  .intro-slider-wrapper {
    position: relative;
    @include flex(center);
    width: 100%;

    .slider-paginator {
      @include wh(48px, 48px);
      @include background('../../../images/icons/button/button-review-paginator.svg', $color: #FFFCFA);
      top: 32%;
      z-index: 99;
      filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, .2));
      border-radius: 48px;
  
      &.prev {
        transform: rotate(180deg);
        @include pos($left: 0);
      }

      &.next {
        @include pos($right: 0);
      }
    }
  }

  .intro-slider {
    width: 100%;
    overflow: hidden;
    position: relative;

    .slides {
      width: 100%;
      @include flex($align: center);
      flex-direction: column;
    }

    .slide:first-child {
      position: absolute;
    }

    .slide {
      flex: 1 0 100%;
      @include flex($align: center, $dir: column);
      transition: all 300ms;
      opacity: 0;

      &-title {
        margin-bottom: 24px;
        max-width: 360px;
      }

      p {
        max-width: 800px;
        color: get-color(text, 2);
  
        & + p {
          margin-top: 8px;
        }
      }

      &-action {
        margin-top: 32px;
      }
    }

    .slide.active {
      opacity: 1;
      z-index: 999999;
    }
  }

  @include mq-max(1240) {
    &-actions {
      grid-template-columns: 230px 1fr 230px;
    }

    &-image {
      img {
        max-width: 100%;
      }
    }
  }

  @include mq-max(970) {
    .intro-slider-wrapper {
      .slider-paginator {
        top: 12px;
      }
    }
  }

  @include mq-max(900) {
    &-header {
      padding: 32px 0 48px;

      .hero-overtitle {
        margin-bottom: 32px;
        font-size: 22px;
        line-height: 20px;
      }

      .hero-title {
        font-size: 26px;
        line-height: 24px;
        max-width: 300px;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
    
    
    &-intro {
      padding-top: 10px;
      padding-bottom: 80px
    }

    .intro-title {
      margin-bottom: 16px;
    }

    .intro-slider-wrapper .slider-paginator.prev,
    .intro-slider-wrapper .slider-paginator.next {
      top: -40px;
    }

    &-actions {
      grid-template-columns: 1fr;
      justify-items: center;
      row-gap: 0;

      nav {
        @include wh(100%, $max-w: $col-3);
      }

      .actions-right {
        margin-top: 16px;
      }
    }

    &-image {
      order: -1;
      margin-bottom: 56px;

      img {
        max-width: $col-5;
      }
    }
  }

  @include mq-max(620) {
    &-image {

      img {
        max-width: 100%;
      }
    }

    .intro-slider-wrapper .slider-paginator.prev,
    .intro-slider-wrapper .slider-paginator.next {
      top: -56px;
    }
  }

  @include mq-max(470) {
    .intro-slider-wrapper {
      .slider-paginator {
        &.prev {
          @include pos(0, $left: 30%);
        }
  
        &.next {
          @include pos(0, $right: 30%);
        }
      }
    }

    .intro-slider {
      padding-top: 88px;

      .slides {
        min-height: 770px;
      }
    }
  }

  @include mq-max(390) {
    &-header {
      padding: 16px;
      
      .hero-title {
        font-size: 30px;
        line-height: 28px;
        max-width: 330px;
      }
    }
  }
}
