/**
 *  General
 *  Header-Page
 */

.header-page-main {
  padding: 68px 0 80px;
  text-align: center;

  .container {
    @include flex($align: center, $dir: column);
  }

  .header-title {
    max-width: 690px;
  }

  .header-subtitle {
    max-width: 560px;
    margin-top: 24px;
  }

  @include mq-max(900) {
    padding: 16px 0 40px;
  }
}
